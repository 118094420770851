import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  //注册
  {
    path: '/register/:categoryId([1-9]\\d*)',
    name: 'Register',
    component: () => import('@/views/Register')
  },
  //公司简介
  {
    path: '/profile',
    name: 'Profile',
    component: () => import('@/views/Profile')
  },
  //公司愿景
  {
    path: '/visions',
    name: 'Visions',
    component: () => import('@/views/Visions')
  },
  //资质荣耀
  {
    path: '/honors',
    name: 'Honors',
    component: () => import('@/views/Honors')
  },
  //注册公司信息
  {
    path: '/register2/:userId([1-9]\\d*)',
    name: 'Register2',
    component: () => import('@/views/Register2')
  },
  //登录
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/Login')
  },
  //首页新闻列表
  {
    path: '/homeNewsDetail/:newsId([1-9]\\d*)',
    name: 'HomeNewsDetail',
    component: () => import('@/views/HomeNewsDetail')
  },
   //首页新闻详情
   {
    path: '/homenews',
    name: 'HomeNews',
    component: () => import('@/views/HomeNews')
  },
  //采购商
  {
    path: '/exhibi/:id([1-9]\\d*)',
    name: 'Exhibi',
    component: () => import('@/views/exhibi/Index')
  },
  //采购商商品搜索
  {
    path: '/exhibi/exbitProductSearch/:keywords?/:id([1-9]\\d*)',
    name: 'ExbitProductSearch',
    component: () => import('@/views/exhibi/ExbitProductSearch')
  },
  {
    path: '/about/:id([1-9]\\d*)',
    name: 'About',
    component: () => import('@/views/exhibi/About')
  },
  {
    path: '/exhibitors/:id([1-9]\\d*)',
    name: 'Exhibitors',
    component: () => import('@/views/exhibi/Exhibitors')
  },
  {
    path: '/products/:id([1-9]\\d*)',
    name: 'Products',
    component: () => import('@/views/exhibi/Products')
  },
  {
    path: '/threed/:id([1-9]\\d*)',
    name: 'Threed',
    component: () => import('@/views/exhibi/Threed')
  },
  {
    path: '/threeProductdetail/:id([1-9]\\d*)',
    name: 'ThreeProductdetail',
    component: () => import('@/views/exhibi/ThreeProductdetail')
  },
  {
    path: '/live/:id([1-9]\\d*)',
    name: 'Live',
    component: () => import('@/views/exhibi/Live')
  },
  {
    path: '/news/:id([1-9]\\d*)',
    name: 'News',
    component: () => import('@/views/exhibi/News')
  },
  {
    path: '/newsdetail/:newsId([1-9]\\d*)/:id([1-9]\\d*)',
    name: 'NewsDetail',
    component: () => import('@/views/exhibi/NewsDetail')
  },
  {
    path: '/category/:categoryId([1-9]\\d*)?/status/:status([1-9]\\d*)?/:childId([1-9]\\d*)?/:date?/:countryId?/:keywords?',
    name: 'Category',
    component: () => import('@/views/Category')
  },
  {
    path: '/aboutUs',
    name: 'AboutUs',
    component: () => import('@/views/AboutUs')
  },
  //供应商
  {
    path: '/supplier/:id([1-9]\\d*)/:exhibitionId([1-9]\\d*)',
    name: 'Supplier',
    component: () => import('@/views/supplier/Index')
  },
  {
    path: '/supplierproduct/:id([1-9]\\d*)/:exhibitionId([1-9]\\d*)/:categoryId([1-9]\\d*)?',
    name: 'SupplierProduct',
    component: () => import('@/views/supplier/Product')
  },
  {
    path: '/supplierabout/:id([1-9]\\d*)/:exhibitionId([1-9]\\d*)',
    name: 'SupplierAbout',
    component: () => import('@/views/supplier/About')
  },
  {
    path: '/contactus/:id([1-9]\\d*)/:exhibitionId([1-9]\\d*)',
    name: 'ContactUs',
    component: () => import('@/views/supplier/ContactUs')
  },
  {
    path: '/productdetail/:id([1-9]\\d*)/:productId([1-9]\\d*)/:exhibitionId([1-9]\\d*)',
    name: 'Productdetail',
    component: () => import('@/views/supplier/ProductDetail')
  },
  {
    path: '/productsearch/:keywords?/:id([1-9]\\d*)/:exhibitionId([1-9]\\d*)',
    name: 'SupplierProductSearch',
    component: () => import('@/views/supplier/ProductSearch')
  },
  {
    path: '/virtualmeeting/:id([1-9]\\d*)/:exhibitionId([1-9]\\d*)',
    name: 'VirtualMeeting',
    component: () => import('@/views/supplier/VirtualMeeting')
  },
  // 账号信息(首页)
  {
    path: '/users/index/:userId([1-9]\\d*)',
    name: 'UsersIndex',
    component: () => import('@/views/users/Index')
  },
   // 账号信息(编辑)
   {
    path: '/users/infoedit/:userId([1-9]\\d*)',
    name: 'InfoEdit',
    component: () => import('@/views/users/InfoEdit')
  },
  // 子账号列表
  {
    path: '/users/employee/:userId([1-9]\\d*)',
    name: 'UsersEmployee',
    component: () => import('@/views/users/Employee')
  },
   // 修改密码
   {
    path: '/users/resetpassword/:userId([1-9]\\d*)',
    name: 'UsersResetpassword',
    component: () => import('@/views/users/Resetpassword1')
  },
  // 子账号编辑
  {
    path: '/users/emplyEdit/:userId([1-9]\\d*)',
    name: 'EmplyEdit',
    component: () => import('@/views/users/EmplyEdit')
  },
  // 子账号添加
  {
    path: '/users/emplyAdd/:userId([1-9]\\d*)',
    name: 'EmplyAdd',
    component: () => import('@/views/users/EmplyAdd')
  },
  // 展品管理
  {
    path: '/users/exhibit/:userId([1-9]\\d*)',
    name: 'UsersExhibit',
    component: () => import('@/views/users/Exhibit')
  },
   // 展会管理
   {
    path: '/users/exhibition/:userId([1-9]\\d*)',
    name: 'UsersExhibition',
    component: () => import('@/views/users/Exhibition')
  },
    // 展会详情
    {
      path: '/users/exhibitionDetail/:id([1-9]\\d*)/:userId([1-9]\\d*)',
      name: 'ExhibitionDetail',
      component: () => import('@/views/users/ExhibitionDetail')
    },
   // 展品添加
   {
    path: '/users/exbitAdd/:userId([1-9]\\d*)',
    name: 'ExbitAdd',
    component: () => import('@/views/users/ExbitAdd')
  },
  // 展品编辑
  {
    path: '/users/exbitEdit/:id([1-9]\\d*)/:userId([1-9]\\d*)/:categoryId1([1-9]\\d*)/:categoryId2([1-9]\\d*)',
    name: 'ExbitEdit',
    component: () => import('@/views/users/ExbitEdit')
  },
  // 展品编辑3d
  {
    path: '/users/exbitEditThree/:id([1-9]\\d*)/:userId([1-9]\\d*)',
    name: 'ExbitEditThree',
    component: () => import('@/views/users/ExbitEditThree')
  },
  // 采购意向
  {
    path: '/users/event/:userId([1-9]\\d*)',
    name: 'UsersEvent',
    component: () => import('@/views/users/Event')
  },
   // 采购意向详情
   {
    path: '/users/eventDetail/:id([1-9]\\d*)/:userId([1-9]\\d*)',
    name: 'EventDetail',
    component: () => import('@/views/users/EventDetail')
  },
  // RFQ管理
  {
    path: '/users/rfq/:userId([1-9]\\d*)',
    name: 'UsersRFQ',
    component: () => import('@/views/users/RFQ')
  },
  // RFQ添加
  {
    path: '/users/rfqAdd/:userId([1-9]\\d*)',
    name: 'RfqAdd',
    component: () => import('@/views/users/RfqAdd')
  },
  // RFQ详情
  {
    path: '/users/rfqDetail/:id([1-9]\\d*)/:userId([1-9]\\d*)',
    name: 'RfqDetail',
    component: () => import('@/views/users/RfqDetail')
  },
  // 会议管理
  {
    path: '/users/management/:userId([1-9]\\d*)',
    name: 'UsersManagement',
    component: () => import('@/views/users/Management')
  },
  // 问卷管理
  {
    path: '/users/questionnaire/:userId([1-9]\\d*)',
    name: 'UsersQuestionnaire',
    component: () => import('@/views/users/Questionnaire')
  },
  // 问卷详情
  {
    path: '/users/questionDetail/:id([1-9]\\d*)/:userId([1-9]\\d*)',
    name: 'QuestionDetail',
    component: () => import('@/views/users/QuestionDetail')
  },
  // 收藏
  {
    path: '/users/collect/:userId([1-9]\\d*)',
    name: 'UsersCollection',
    component: () => import('@/views/users/Collect')
  },
   // 视频管理
   {
    path: '/users/video/:userId([1-9]\\d*)',
    name: 'UsersCompanyVideo',
    component: () => import('@/views/users/Video')
  },
   // 视频添加
   {
    path: '/users/videoAdd/:userId([1-9]\\d*)',
    name: 'VideoAdd',
    component: () => import('@/views/users/VideoAdd')
  },
   // 视频编辑
   {
    path: '/users/videoEdit/:id([1-9]\\d*)/:userId([1-9]\\d*)',
    name: 'VideoEdit',
    component: () => import('@/views/users/VideoEdit')
  },
  // 查看用户信息
  {
   path: '/users/purchaserInfo/:id([1-9]\\d*)/:userId([1-9]\\d*)',
   name: 'PurchaserInfo',
   component: () => import('@/views/users/PurchaserInfo')
  },
  // 云信聊天
  {
    path: '/talking/:adminId([1-9]\\d*)?',
    name: 'Talking',
    component: () => import('@/views/Talking')
  }
]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

export default router
