import Vue from 'vue';
import Antd from 'ant-design-vue';
import App from './App';
import 'ant-design-vue/dist/antd.css';
import router from './router';
import store from './store';
import i18n from './locales';
import { VueAxios } from './utils/request';
import common from './utils/common';
import VideoPlayer from 'vue-video-player';
import 'vue-video-player/src/custom-theme.css';
import 'video.js/dist/video-js.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button } from 'ant-design-vue';
import layer from 'vue-layer'
import 'vue-layer/lib/vue-layer.css';
import './utils/filter'


Vue.prototype.$layer = layer(Vue);

Vue.use(Button);
Vue.use(VideoPlayer);
Vue.use(Antd);
Vue.use(VueAxios);

Vue.config.productionTip = false;
Vue.prototype.$common = common;

router.afterEach(( to, from, next ) => {
  setTimeout(()=>{
    // 此处粘贴百度统计复制过来的代码
    var _hmt = _hmt || [];
    (function() {
      var hm = document.createElement("script");
      hm.src = "https://hm.baidu.com/hm.js?df3e7a55c76f5f84d0f45e1c7be479bd";
      var s = document.getElementsByTagName("script")[0]; 
      s.parentNode.insertBefore(hm, s);
    })();
  },0);
});

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app');

