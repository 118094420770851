import antdEnUS from 'ant-design-vue/es/locale-provider/en_US'
import momentEU from 'moment/locale/eu'
import global from './en-US/global'

import menu from './en-US/menu'
import setting from './en-US/setting'
import user from './en-US/user'

import dashboard from './en-US/dashboard'
import form from './en-US/form'
import result from './en-US/result'
import account from './en-US/account'

const components = {
  antLocale: antdEnUS,
  momentName: 'eu',
  momentLocale: momentEU
}

export default {
  message: '-',

  'layouts.usermenu.dialog.title': 'Message',
  'layouts.usermenu.dialog.content': 'Are you sure you would like to logout?',
  'layouts.userLayout.title': 'Ant Design is the most influential web design specification in Xihu district',
  'lang.language': 'English',
  'lang.english': 'English',
  'lang.chinese': 'Chinese',
  'signUp': 'sign up',
  'signUp.supplier': 'Supplier',
  'signUp.buyer': 'Buyer',
  'twitter': 'Twitter',
  'facebook': 'Facebook',
  'supplier': 'Supplier',
  'buyers': 'Buyers',
  'search': 'Search',
  'trade': 'Please enter keywords to find a trade show',
  'categories': 'Industry Categories',
  'home': 'Home',                  
  'exhibition': 'Exhibition',                  
  'service': 'Service',
  'news': 'News',
  'about': 'About Us',
  'clients': 'Our Clients',
  'recent.exhibition': 'On-going Events',
  'past.exhibition': 'Past Exhibitions',
  'see.more': 'See more',
  'enter': 'ENTER',
  'finish': 'BROWSE',
  'new': 'News',
  'industry': 'Industry',
  'data': 'Date',
  'area': 'Area',
  'look': 'What are you looking for...',
  'ongo': 'Ongoing',
  'home': 'Home',                                    
  'regist': 'Registration',
  'About': 'About',
  'exhibitors': 'Exhibitors',
  'products': 'Products',
  '3D': '3D',
  'live': 'Live Streaming',
  'search.kewords': 'Please enter keywords',
  'ContactUs1': 'About Us',
  'all': 'all',
  'getcontact': 'Get Contact Details(Send Business Card) ',
  'chat': 'Chat with exhibitor',
  'meeting': 'Virtual Meeting',
  'slive': 'Live',
  'link': 'Links',
  'userinfo': 'Personal Center',
  'about.exhibition': 'About Exhibition',
  'enter.hall': 'Enter The Hall',
  'sponsor': 'Sponsor and organizer',
  'organiser': 'co-organiser',
  'audited.supplier': 'Audited Supplier',
  'chat.exhibitor': 'Chat with exhibitor',
  'company.name' : 'Company Name',
  'address': 'Address',
  'postalcode': 'Postal Code',
  'contact': 'Contact',
  'title': 'Title',
  'mobile.number': 'Mobile Number',
  'email': 'Email',
  'em': 'Email',
  'telephone': 'Telephone',
  'please.enter': 'please enter',
  'fax': 'Fax',
  'weChat': 'WeChat',
  'whatsApp': 'whatsApp',
  'contact.now': 'Contact Now',
  'pcategories': 'Categories',
  'products.list': 'Products List',
  'news.detail': 'News detail',
  'registration': 'Registration',
  'register.buyer': 'Register as a buyer',
  'register.exhibitor': 'Register as an exhibitor',
  'live.video': 'Live video',
  'product.recommendation': 'Product Recommendation',
  'FOBPrice': 'FOB Price',
  'Min.Order': 'Min. Order',
  'Company.Profile': 'Company Profile',
  'Account.information': 'Account information',
  'Account.number': 'Account number cannot be empty',
  'code': 'Verification Code',
  'password': 'password',
  'Password': 'Password',
  'case.sensitive': '6-16 digits plus letters, case sensitive',
  'password.incorrect': 'The password format is incorrect',
  'Confirm.password': 'Confirm password',
  'two.passwords': 'The two passwords are inconsistent',
  'register': 'register',
  'correctly': 'Please fill in the data correctly',
  'Company.information': 'Company information',
  'corporate.name': 'corporate name',
  'Industry.type': 'Industry type',
  'select.industry': 'Please select industry',
  'fill.correctly': 'Please fill in the company information and industry type correctly',
  'MainProducts': 'Main Products',
  'TradeTerms': 'Trade Terms',
  'Category': 'Category',
  'ALL': 'ALL',
  'ContactUs': 'Contact Us',
  'TradeFair': 'Trade Fair',
  'Webinar': 'Webinar',
  'HybridExpo': 'HybridExpo',
  'PaymentMethod': 'Payment Method',
  'Keywords': 'Keywords',
  'Favorites': 'Add to Favorites',
  'removeFavorites': 'Cancel collection',
  'contact.exhibitor': 'contact exhibitor',
  'Send.cards': 'Send business cards',
  'ProductDetails': 'Product Details',
  'TExhibitorProfile': 'TExhibitor Profile',
  'To': 'To',
  'Subject': 'Subject',
  'Purchasequantity': 'Purchase quantity',
  'Please.select': 'Please select',
  'platform': 'platform',
  'only': 'only',
  'individual': 'individual',
  'submit': 'Submit',
  'Check.all': 'Check all',
  'other': 'Other',
  'othercate': 'OtherCategory',
  'Introduction': 'Introduction',
  'CountryRegion': 'Country and Region',
  'ContactName': 'Contact Name',
  'ContactEmail': 'Contact Email',
  'ContactJobTitle': 'Contact Job Title',
  'ContactMob': 'Contact Mob',
  'ContactTel': 'Contact Tel',
  'ContactFax': 'Contact Fax',
  'Website': 'Website',
  'ChooseExhibition': 'Choose  Exhibition',
  'register.first': 'If you have not registered for this exhibition, please register first',
  'AppointmentTime': 'Appointment Time',
  'select.time': 'Please select the time',
  'Featured.exhibits': 'Featured exhibits',
  'Undertaker': 'Undertaker',
  'signout': 'sign out',
  'login': 'Login',
  'Mobile.email': 'Mobile/Email',
  'Account.empty': 'Account cannot be empty',
  'Vcodelogin': 'Verification code login',
  'Passwordlogin': 'Password login',
  'aboutUs': 'As a well established event organizer in China, JNICE\'s E2XPO is committed to helping our clients in virtual event management, by developing helpful solutions to make their business easier. We provide applications to lessen the ardouos tasks of organizers，exhibitors and visitors worldwide in various scenarios from trade fairs and conferences to webinars, openings and product launchings as well as B2B networking activities. E2XPO is your assistance in event management to make your live or virtual event as fantastic, smooth and memorable as it can be!',
  'Negotiable': 'Negotiable',
  'MOQ': 'MOQ',
  'send': "send",
  'comtitle': 'Jiangsu New International Convention & Exhibition (Group) Co., Ltd',
  'visions': 'Visions',
  'Honors': 'Qualifications & Honors',
  'CompanyProfile': 'Company Profile',
  'status': 'status',
  'Profile': 'Profile',
  'TEL': 'TEL',
  'ADD': 'ADD',
  'E-mail': 'E-mail',
  'Followus': 'Follow us',
  'categoryName': 'Classification name',
  'exhibitionName': 'Exhibition Name',
  'exhibitionTitle': 'Exhibition title',
  'exhibitionAddress': 'Venue of the exhibition',
  'exhibitionCategory': 'The exhibition includes industries',
  'exhibitionZone': 'Exhibition zoning information',
  'zone': 'partition',
  'zoneNumber': 'Partition number',
  'title': 'title',
  'detail': 'details',
  'updating': 'Updating',
  ...components,
  ...global,
  ...menu,
  ...setting,
  ...user,
  ...dashboard,
  ...form,
  ...result,
  ...account
}
