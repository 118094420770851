<template>
  <div>
    <Header></Header>
    <!-- 轮播图 -->
    <a-carousel autoplay class="bannerimg">
      <div v-for="(item, index) in broadcast" :key="index">
        <a :href="item.broadcastUrl"><img :src="item.broadcastImage" alt=""></a>
      </div>
    </a-carousel>
    <!-- 近期展览 -->
    <div class="exbihit" v-if="recents != ''">
      <div class="exbit">
        <div class="econt">{{ $t('recent.exhibition') }}</div>
        <div class="hor">
          <a-divider type="horizontal" />
                <router-link :to="{ name: 'Category', params: {status: 2} }">
            {{ $t('see.more') }} <a-icon type="right" class="hr"/>
          </router-link>
        </div>
        <div class="einfo">
          <div v-for="(item, index) in recents" :key="index" @click="goto('Exhibi', { id: item.exhibitionId }, 'blank')">
            <div class="eimg">
              <img :src="item.thumb">
            </div>
            <div class="e1">{{ item.country }}</div>
            <div class="e2">{{ item.exhibitionName }}</div>
            <div class="e3">
              <div class="eleft">
                <div class="el1">{{ item.time }}</div>
                <div class="el2">{{ item.address }}</div>
              </div>
              <div class="eright"> {{ $t('enter') }} </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 往期展会 -->
    <div class="pexhitbit" v-if="pasts != ''">
      <div class="pex">
        <div class="pimg">
           <img src="@/assets/images/e7.png" alt="">
        </div>
        <div class="econt">{{ $t('past.exhibition') }}</div>
        <div class="hor">
          <a-divider type="horizontal" />
          <router-link :to="{ name: 'Category', params: {status: 3} }">
            {{ $t('see.more') }} <a-icon type="right" class="hr"/>
          </router-link>
        </div>
        <div class="pinfo">
          <div v-for="(item, index) in pasts" :key="index" @click="goto('Exhibi', { id: item.exhibitionId }, 'blank')">
            <div class="eimg">
              <img :src="item.thumb">
            </div>
            <div class="econtent">
              <div class="e1">{{ item.country }}</div>
              <div class="e2">{{ item.exhibitionName }}</div>
              <div class="e3">
                <div class="eleft">
                  <div class="el1">{{ item.time }}</div>
                  <div class="el2">{{ item.address }}</div>
                </div>
                <div class="pright"> {{ $t('finish') }} </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>  
    <!-- 新闻 -->
    <div class="new" v-if="news != ''">
      <div class="news">
        <div class="econt">{{ $t('new') }}</div>
        <div class="hor">
          <a-divider type="horizontal" />
          <router-link to='/HomeNews'>
            {{ $t('see.more') }} <a-icon type="right" class="hr"/>
          </router-link>
        </div>
        <div class="ninfo">
          <div class="nleft" v-if="news.length > 0">
            <div class="nlimg">
              <img :src="news[0].image" alt="">
            </div>
            <div class="ncontent">
              <div class="n1">{{ news[0].title }}</div>
              <div class="n2">
                {{ news[0].brief }}
              </div>
            </div>
          </div>
          <div class="nright">
            <template v-if="news.length > 1">
              <template v-for="(item, index) in news">
                <div v-if="index > 0" :key="index" class="news-item">
                  <div class="nr">
                    <div class="ni1">{{ item.title }}</div>
                    <div class="ni2">{{ item.brief }}</div>
                  </div>
                </div>
              </template>
            </template>
          </div>
        </div>
      </div>
    </div>
    <!-- 底部轮播 -->
    <!-- 合作伙伴 -->
    <div class="footerimg" v-if="cooperations != ''">
      <div class="footerimg2">
          <div class="colents"> {{ $t('clients') }}</div>
          <a-carousel class="colentsimg">
            <div v-for="k in Math.ceil(cooperations.length / 12)" :key="k" class="colentsimg2">
              <div v-for="(item, index) in cooperations.slice((k - 1) * 12, k * 12)" :key="index" class="itemimg">
                <img :src="item.cooperationLogo">
              </div>
            </div>
          </a-carousel>
      </div>
    </div>
    <div class="bottom"></div>
    <!-- 友情链接 -->
    <div class="footerimg">
      <div class="footerimg2">
          <div class="colents"> {{ $t('link') }}</div>
          <a-carousel class="colentsimg">
            <div v-for="k in Math.ceil(linkers.length / 3)" :key="k" class="colentsimg2">
              <div v-for="(item, index) in linkers.slice((k - 1) * 3, k * 3)" :key="index" class="itemimg">
                <a :href="item.url"><img :src="item.linkerLogo"></a>
              </div>
            </div>
          </a-carousel>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import storage from 'store'
import Header from './components/Header'
import Footer from './components/Footer'

export default {
   components: {
    Footer,
    Header
  },
  data() {
    return {
      lang: this.$store.getters.lang,
      imglist : [
        [require('@/assets/images/1.png'),require('@/assets/images/2.png'),require('@/assets/images/3.png'),require('@/assets/images/4.png'),
        require('@/assets/images/5.png'),require('@/assets/images/6.png'),require('@/assets/images/7.png'),require('@/assets/images/8.png'),
        require('@/assets/images/9.png'),require('@/assets/images/10.png'),require('@/assets/images/11.png'),require('@/assets/images/12.png'),
        ],
        [require('@/assets/images/1.png'),require('@/assets/images/2.png'),require('@/assets/images/3.png'),require('@/assets/images/4.png'),
        require('@/assets/images/5.png'),require('@/assets/images/6.png'),require('@/assets/images/7.png'),require('@/assets/images/8.png'),
        require('@/assets/images/9.png'),require('@/assets/images/10.png'),require('@/assets/images/11.png'),require('@/assets/images/12.png'),
        ],
        [require('@/assets/images/1.png'),require('@/assets/images/2.png'),require('@/assets/images/3.png'),require('@/assets/images/4.png'),
        require('@/assets/images/5.png'),require('@/assets/images/6.png'),require('@/assets/images/7.png'),require('@/assets/images/8.png'),
        require('@/assets/images/9.png'),require('@/assets/images/10.png'),require('@/assets/images/11.png'),require('@/assets/images/12.png'),
        ]
      ],
      broadcast: [], // 轮播图
      recents: [], // 近期展会
      pasts: [], // 往期展会
      news: [], // 新闻
      cooperations: [], // 合作伙伴
      linkers: [],  //友情链接
      token: localStorage.token,
    }
  },
  mounted() {
    this.getIndex()
  },
  methods: {
    getIndex () {
      this.$common.fetchList('/index', {}).then(data => {
        if (data.code === 200) {
          const result = data.datas
          for(const [k, v] of Object.entries(result)) {
            this[k] = v
          }
        }
      }).catch(() => {
        this.$notification.error({
          message: '错误',
          description: '请求信息失败，请重试'
        })
      })
    },
    // 链接地址跳转
    goto (name, params = {}, type = 'current') {
      // 跳转到目的路由
      // 打开新页面
      if (type == 'blank') {
        const link = this.$router.resolve({ name: name, params: params })
        window.open(link.href, '_blank')
      } else if (type == 'current') { // 当前页面打开
        this.$router.push({ name: name, params: params })
      }
    }
  }
}
</script>

<style lang="less" scoped>
  @import '~@/assets/css/home.less';
</style>
