<template>
  <div class="foot">
    <div class="footer">
      <div class="logoimg">
        <img src="@/assets/images/botlogo.jpg" alt="" />
      </div>
      <div class="ftwo">
        <div class="first">JNICE GROUP</div>
        <div class="second sto">
          <router-link :to="{ name: 'Profile' }">{{
            $t("Profile")
          }}</router-link>
        </div>
        <div class="second">
          <router-link :to="{ name: 'Visions' }">{{
            $t("visions")
          }}</router-link>
        </div>
        <div class="second" style="width:147px;">
          <router-link :to="{ name: 'Honors' }">{{ $t("Honors") }}</router-link>
        </div>
        <!-- <div class="second">Store Locations and Even</div> -->
      </div>
      <div class="ftwo">
        <div class="first">{{ $t("ContactUs") }}</div>
        <div class="second">{{ $t("TEL") }}: 0086-25-52327665/ 84609603</div>
        <div class="second">
          {{ $t("ADD") }}:5th FL, Sub-building of Jiangsu Int'l Trade
          Mansion,50# RD.zhonghua,Nanjing, Jiangsu Province, PR. China
        </div>
        <!-- <div class="second">RD.zhonghua,Nanjing, Jiangsu Province, PR. China</div> -->
        <div class="second">{{ $t("E-mail") }}: {{ list.site_email }}</div>
        <!-- <div class="second">Store Locations and Even</div> -->
      </div>
      <div class="ftwo">
        <div class="first">{{ $t("Followus") }}</div>
        <div class="second sto2">
          <div>
            <a
              :href="list.twitter"
              @mouseover="mouseOver"
              @mouseleave="mouseLeave"
              target="_blank"
              ><img :src="tuite" alt=""
            /></a>
          </div>
          <div class="img2">
            <a
              :href="list.facebook"
              @mouseover="mouseOver1"
              @mouseleave="mouseLeave1"
              target="_blank"
              ><img :src="face" alt=""
            /></a>
          </div>
          <div class="img2">
            <a
              :href="list.instagram"
              @mouseover="mouseOver2"
              @mouseleave="mouseLeave2"
              target="_blank"
              ><img :src="im" alt=""
            /></a>
          </div>
        </div>
      </div>
    </div>
    <div class="foot2">
      <div>
        <a href="https://beian.miit.gov.cn" target="_blank">{{
          list.site_icp
        }}</a
        >|<img src="@/assets/images/icp.jpg" height="12px" />苏公网安备
        32010402001227号
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      logoimg: require("@/assets/images/logo2.png"),
      list: [],
      tuite: require("@/assets/images/twitter.png"),
      face: require("@/assets/images/facebook.png"),
      im: require("@/assets/images/ing2.png"),
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      this.$common
        .fetchList("/index/about/us", {})
        .then((data) => {
          if (data.code === 200) {
            const result = data.data;
            this.list = result;
          }
        })
        .catch(() => {
          // this.$notification.error({
          //   message: '错误',
          //   description: '请求信息失败，请重试'
          // })
        });
    },
    //移入改变图片
    mouseOver() {
      this.tuite = require("@/assets/images/tuite.png");
    },
    mouseLeave() {
      this.tuite = require("@/assets/images/twitter.png");
    },
    //移入改变图片
    mouseOver1() {
      this.face = require("@/assets/images/face2.png");
    },
    mouseLeave1() {
      this.face = require("@/assets/images/facebook.png");
    },
    //移入改变图片
    mouseOver2() {
      this.im = require("@/assets/images/in.png");
    },
    mouseLeave2() {
      this.im = require("@/assets/images/ing2.png");
    },
  },
};
</script>

<style lang="less" scoped>
@import "~@/assets/css/footer";
</style>
