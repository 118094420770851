import antd from 'ant-design-vue/es/locale-provider/zh_CN'
import momentCN from 'moment/locale/zh-cn'
import global from './zh-CN/global'

import menu from './zh-CN/menu'
import setting from './zh-CN/setting'
import user from './zh-CN/user'
import dashboard from './zh-CN/dashboard'
import form from './zh-CN/form'
import result from './zh-CN/result'
import account from './zh-CN/account'

const components = {
  antLocale: antd,
  momentName: 'zh-cn',
  momentLocale: momentCN
}

export default {
  message: '-',

  'layouts.usermenu.dialog.title': '信息',
  'layouts.usermenu.dialog.content': '您确定要注销吗？',
  'layouts.userLayout.title': 'Ant Design 是西湖区最具影响力的 Web 设计规范',
  'lang.language': '中文',
  'lang.english': '英文',
  'lang.chinese': '中文',
  'signUp': '注册',
  'signUp.supplier': '成为供应商',
  'signUp.buyer': '成为采购商',
  'twitter': '推特',
  'facebook': '脸书',
  'supplier': '供应商登录',
  'buyers': '采购商登录',
  'search': '搜索',
  'trade': '请输入关键字搜索展会',
  'categories': '行业类别',
  'home': '首页',                  
  'exhibition': '展会',                  
  'service': '服务',
  'news': '新闻',
  'about': '关于我们',   
  'clients': '合作伙伴',
  'recent.exhibition': '近期展会',
  'past.exhibition': '往期展会',
  'see.more': '查看更多',
  'enter': '进入',
  'finish': '完成',
  'new': '新闻',
  'industry': '行业',
  'data': '日期',
  'area': '地区',
  'look': '你在找什么。。。',
  'ongo': '持续',
  'home': '首页',
  'regist': '注册',
  'About': '关于',
  'exhibitors': '参展商',
  'products': '产品',
  '3D': '3D',
  'live': '直播',
  'search.kewords': '请输入关键字',
  'ContactUs': '联系我们',
  'all': '全部',
  'getcontact': '获取联系方式（发送名片）',
  'chat': '与参展商聊天',
  'meeting': '视频会议',
  'slive': '直播',
  'link': '友情链接',
  'userinfo': '个人中心',
  'about.exhibition': '关于展会',
  'enter.hall': '进入大厅',
  'sponsor': '主办方和组织者',
  'organiser': '协办者',
  'audited.supplier': '经审核的供应商',
  'chat.exhibitor': '与参展商聊天',
  'company.name' : '公司名称',
  'address': '地址',
  'postalcode': '邮政编码',
  'contact': '联系人',
  'title': '称谓',
  'mobile.number': '手机号码',
  'email': '电子邮件',
  'em': '邮箱',
  'telephone': '电话',
  'please.enter': '请输入',
  'fax': '传真',
  'weChat': '微信',
  'whatsApp': 'whatsApp',
  'contact.now': '现在联系',
  'pcategories': '类别',
  'products.list': '产品清单',
  'news.detail': '新闻详情',
  'registration': '预约报名',
  'register.buyer': '注册为采购商',
  'register.exhibitor': '注册为参展商',
  'live.video': '现场视频',
  'product.recommendation': '产品推荐',
  'FOBPrice': '离岸价格',
  'Min.Order': '最小订单',
  'Company.Profile': '公司简介',
  'Account.information': '账户信息',
  'Account.number': '账号不能为空',
  'code': '验证码',
  'password': '密码',
  'Password': '密码',
  'case.sensitive': '6-16位数字加字母，区分大小写',
  'password.incorrect': '密码格式不正确',
  'Confirm.password': '确认密码',
  'two.passwords': '两次密码不一致',
  'register': '注册',
  'correctly': '请正确填写数据',
  'Company.information': '公司信息',
  'corporate.name': '公司名称',
  'Industry.type': '行业类型',
  'select.industry': '请选择行业',
  'fill.correctly': '请正确填写公司信息和行业类型',
  'MainProducts': '主要产品',
  'TradeTerms': '贸易条件',
  'Category': '类别',
  'ALL': '全部',
  'ContactUs1': '关于我们',
  'TradeFair': '交易会',
  'Webinar': '网络研讨会',
  'HybridExpo': '线上线下融合展',
  'PaymentMethod': '付款方式',
  'Keywords': '关键字',
  'Favorites': '添加到收藏夹',
  'removeFavorites': '取消收藏',
  'contact.exhibitor': '联系参展商',
  'Send.cards': '寄名片',
  'ProductDetails': '产品详情',
  'TExhibitorProfile': '展会介绍',
  'To': '致',
  'Subject': '项目',
  'Purchasequantity': '采购数量',
  'Please.select': '请选择',
  'platform': '台',
  'only': '只',
  'individual': '个',
  'submit': '提交',
  'Check.all': '选择全部',
  'other': '其他',
  'othercate': '其他行业',
  'Introduction': '介绍',
  'CountryRegion': '国家和地区',
  'ContactName': '联系人姓名',
  'ContactEmail': '联系人邮箱',
  'ContactJobTitle': '联系人职位',
  'ContactMob': '联系人手机',
  'ContactTel': '联系人电话',
  'ContactFax': '联系人传真',
  'Website': '网站',
  'ChooseExhibition': '选择展会',
  'register.first': '如果您尚未注册本次展会，请先注册',
  'AppointmentTime': '预约时间',
  'select.time': 'Please select the time',
  'Featured.exhibits': '特色展品',
  'Undertaker': '承办方',
  'signout': '退出',
  'login': '登录',
  'Mobile.email': '手机号/邮箱',
  'Vcodelogin': '验证码登录',
  'Passwordlogin': '密码登录',
  'aboutUs': 'E²XPO平台是由江苏新国际会展集团有限公司开发运营的一站式全流程数字会展平台。平台秉承“引领会展业高质量发展”的使命，致力于成为会展主办方、服务方和参会人群的基础连接者。公司运用互联网、人工智能、大数据和云计算等技术，针对会议、展览、活动等线上和线下场景，推出解决方案，逐步形成云上会展数字化智能平台，成为推动中国会展产业升级和创新的重要力量。E²XPO将通过数字化虚拟展馆、实时互动交易、智能商贸洽谈、全链路参会体验、展会生态服务、行业大数据资讯等平台的建设，服务云上会展新业态，为会展业发展贡献创新力量，迎接线上线下融合的“云上会展”时代的到来。未来，E²XPO将持续借助会展数字化平台优势和技术力量，赋能更多会展活动主承办、场馆运营、会展服务等上下游企业，做好会展三方的基础连接，为实现会展业的高质量发展而不懈努力。',
  'Negotiable': '可议价',
  'MOQ': '最小起订量',
  'send': "发送验证码",
  'comtitle': '江苏新国际会展集团有限公司',
  'visions': '愿景',
  'Honors': '资质荣誉',
  'CompanyProfile': '公司介绍',
  'status': '状态',
  'Profile': '简介',
  'TEL': '电话',
  'ADD': '地址',
  'E-mail': '邮箱',
  'Followus': '关注我们',
  'categoryName': '分类名称',
  'exhibitionName': '展会名称',
  'exhibitionTitle': '展会标题',
  'exhibitionAddress': '展会举办地',
  'exhibitionCategory': '展会包含行业',
  'exhibitionZone': '展会分区信息',
  'zone': '分区',
  'zoneNumber': '分区号',
  'exhibitionCategory': '展会包含行业',
  'title': '标题',
  'detail': '详情',
  'updating': '正在升级中',
  ...components,
  ...global,
  ...menu,
  ...setting,
  ...user,
  ...dashboard,
  ...form,
  ...result,
  ...account
}
