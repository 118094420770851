export default {
  'user.login.userName': 'userName',
  'user.login.password': 'password',
  'user.login.username.placeholder': 'Account: admin',
  'user.login.password.placeholder': 'password: admin or ant.design',
  'user.login.message-invalid-credentials':
    'Invalid username or password（admin/ant.design）',
  'user.login.message-invalid-verification-code': 'Invalid verification code',
  'user.login.tab-login-credentials': 'Credentials',
  'user.login.tab-login-mobile': 'Mobile number',
  'user.login.mobile.placeholder': 'Mobile number',
  'user.login.mobile.verification-code.placeholder': 'Verification code',
  'user.login.remember-me': 'Remember me',
  'user.login.forgot-password': 'Forgot your password?',
  'user.login.sign-in-with': 'Sign in with',
  'user.login.signup': 'Sign up',
  'user.login.login': 'Login',
  'user.register.register': 'Register',
  'user.register.email.placeholder': 'Email',
  'user.register.password.placeholder': 'Password ',
  'user.register.password.popover-message': 'Please enter at least 6 characters. Please do not use passwords that are easy to guess. ',
  'user.register.confirm-password.placeholder': 'Confirm password',
  'user.register.get-verification-code': 'Get code',
  'user.register.sign-in': 'Already have an account?',
  'user.register-result.msg': 'Account：registered at {email}',
  'user.register-result.activation-email':
    'The activation email has been sent to your email address and is valid for 24 hours. Please log in to the email in time and click on the link in the email to activate the account.',
  'user.register-result.back-home': 'Back to home',
  'user.register-result.view-mailbox': 'View mailbox',
  'user.email.required': 'Please enter your email!',
  'user.email.wrong-format': 'The email address is in the wrong format!',
  'user.userName.required': 'Please enter account name or email address',
  'user.password.required': 'Please enter your password!',
  'user.password.twice.msg': 'The passwords entered twice do not match!',
  'user.password.strength.msg':
    'The password is not strong enough',
  'user.password.strength.strong': 'Strength: strong',
  'user.password.strength.medium': 'Strength: medium',
  'user.password.strength.low': 'Strength: low',
  'user.password.strength.short': 'Strength: too short',
  'user.confirm-password.required': 'Please confirm your password!',
  'user.phone-number.required': 'Please enter your phone number!',
  'user.phone-number.wrong-format': 'Please enter a valid phone number',
  'user.verification-code.required': 'Please enter the verification code!',
  'user.account': 'account',
  'user.current-account': 'Current account',
  'user.yzm': 'Verification Code',
  'user.new_pass': 'New password',
  'user.confirm_pass': 'Confirm password',
  'user.get_yzm': 'send',
  'user.center': 'Personal Center',
  // 公司基本信息
  'user.info': 'Basic information of the company',
  'user.info.en': 'English name of the company',
  'user.info.zh': 'Chinese name of the company',
  'user.info.area': 'Region of the company',
  'user.info.select-country': 'Please select a country',
  'user.info.address-zh': 'Chinese specific address',
  'user.info.address-en': 'English specific address',
  'user.info.company-tel': 'Company Office Number',
  'user.info.website': 'Official website',
  'user.info.contact-tel': 'contact number',
  'user.info.fax': 'Fax',
  'user.info.contact': 'Contact information (account)',
  'user.info.first-name': 'given name',
  'user.info.last-name': 'family name',
  'user.info.chinese-name': 'Chinese name',
  'user.info.gender': 'Gender',
  'user.info.gender-0': 'unknown',
  'user.info.gender-1': 'male',
  'user.info.gender-2': 'female',
  'user.info.tel': 'Telephone',
  'user.info.position': 'position',
  'user.info.email': 'email',
  'user.info.wechat': 'WeChat',
  'user.info.business': 'Company business information',
  'user.info.main': 'Main business',
  'user.info.main-zh': 'Main business (Chinese)',
  'user.info.main-en': 'Main business (English)',
  'user.info.desc-zh': 'Company description (Chinese)',
  'user.info.desc-en': 'Company description (English)',
  'user.info.qualification': 'Company qualification picture',
  'user.info.qualification-upload': 'Upload qualification picture',
  'user.info.company-logo': 'Company logo',
  'user.info.company-logo-upload': 'Upload logo',
  'user.info.company-img': 'Company image',
  'user.info.company-img-upload': 'Upload image',
  'user.info.index-banner': 'Banner diagram of home page',
  'user.info.index-banner-upload': 'Upload banner',
  'user.info.company-video': 'Company video',
  'user.info.company-video-upload': 'Upload video',
  'user.info.pro-category': 'Product classification',
  'user.info.price-terms': 'Price terms',
  'user.edit': 'edit',
  'user.save': 'save',
  'user.add': 'add',
  'user.delete': 'delete',
  'user.save': 'save',
  'user.cancel': 'cancel',
  'user.number': 'Serial number',
  'user.audit-status': 'Audit status',
  'user.info.account': 'account number',
  'user.operation': 'operation',
  'user.edit3d': 'Edit 3D',
  'user.on': 'Put on the shelf',
  'user.off': 'Off the shelf',
  'user.detail': 'details',
  'user.area': 'region',
  'user.picture': 'picture',
  'user.picture-upload': 'Upload picture',
  'user.yes': 'yes',
  'user.no': 'no',
  'user.keywords': 'keywords',
  'user.keywords-zh': 'Keywords (Chinese)',
  'user.keywords-en': 'Keywords (English)',
  'user.keywords-more': 'Multiple keywords are separated by "|"',
  'user.base-info': 'Base Information',
  'user.contact-info': 'contact information',
  'user.product.name': 'Product name',
  'user.product.name-zh': 'Product name(Chinese)',
  'user.product.name-en': 'Product name(English)',
  'user.product.category-one': 'Primary classification',
  'user.product.category-two': 'Secondary classification',
  'user.product.max-price': 'Highest price',
  'user.product.min-price': 'minimum price',
  'user.product.min-order': 'Minimum order quantity',
  'user.product.quantity': 'Product quantity',
  'user.product.keywords': 'Product keywords',
  'user.product.desc-zh': 'Product description (Chinese)',
  'user.product.desc-en': 'Product description (English)',
  'user.product.min-price1': 'lowest price',
  'user.product.max-price1': 'highest price',
  'user.product.pay-method': 'Payment method (abbreviation)',
  'user.product.img': 'Product cover',
  'user.product.imgs': 'Product picture collection',
  'user.product.imgs-upload': 'Upload picture collection',
  'user.product.videos': 'Product video collection',
  'user.inquiry-title': 'Inquiry title',
  'user.purchaser.info': 'Purchaser company information',
  'user.purchaser.company-name': "'Purchaser's company name",
  'user.buy-number': 'Purchase quantity',
  'user.inquiry-status': 'Inquiry status',
  'user.rfq.info': 'RFQ information',
  'user.rfq.title': 'RFQ title',
  'user.rfq.status': 'RFQ status',
  'user.rfq.price-info': 'RFQ quotation information',
  'user.rfq.price': 'RFQ quotation',
  'user.rfq.desc': 'RFQ description',
  'user.effective-date': 'Effective date',
  'user.pay-type': 'Payment method',
  'user.delivery-place': 'Place of delivery',
  'user.select-cate': 'Please select a category',
  'user.budget': 'budget',
  'user.attachment': 'attachment',
  'user.attachment-upload': 'Upload attachment',
  'user.submit': 'Submit',
  'user.company-name': 'company name',
  'user.company-addr': 'company address',
  'user.company-area': 'Company area',
  'user.desc': 'description',
  'user.price': 'Price',
  'user.status': 'status',
  'user.select': 'select',
  'user.check': 'Check the details',
  'user.send-message': 'Send messages',
  'user.attachment-info': 'RFQ attachment file information',
  'user.meeting.type': 'Meeting type',
  'user.meeting.addr': 'Meeting address',
  'user.meeting.startTime': 'Meeting start time',
  'user.meeting.endTime': 'Meeting end time',
  'user.meeting.status': 'Meeting status',
  'user.meeting.supplier-company-name': 'Supplier company name',
  'user.meeting.isaccept': 'Accept or decline the invitation',
  'user.meeting.accept': 'Accept',
  'user.meeting.accept-status': 'Accept',
  'user.meeting.decline': 'Decline',
  'user.meeting.decline-status': 'Decline',
  'user.meeting.handle-accept-status': 'Are you sure you want to accept it？',
  'user.meeting.handle-decline-status': 'Are you sure you want to refuse？',
  'user.meeting.purchaser': 'Archive',
  'user.meeting.chat': 'chat',
  'user.meeting.start': 'start meeting',
  'user.meeting.startup': 'start up',
  'user.questionnaire.title': 'Questionnaire title',
  'user.questionnaire.status': 'Questionnaire status',
  'user.questionnaire.fill': 'Whether to fill in the questionnaire',
  'user.startTime': 'start time',
  'user.endTime': 'end time',
  'user.exhibition.info': 'Basic information of the exhibition',
  'user.exhibition.contact-product': 'Exhibition related goods',
  'user.exhibition.cancel-contact': 'Disassociate',
  'user.exhibition.no-contact-product': 'Commodities not associated with the exhibition',
  'user.exhibition.contact': 'relation',
  'user.sort': 'sort',
  'user.isOnShelf': 'Is it on the shelf',
  'user.video.title': 'Video title',
  'user.video.title-en': 'Video title (English)',
  'user.video.img': 'Video cover',
  'user.video.img-upload': 'Upload cover image',
  'user.video': 'video',
  'user.video-upload': 'Upload video',
  'user.disable': 'Disable',
  'user.enable': 'Enable',
  'user.wait-check': 'To be reviewed',
}
