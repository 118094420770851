<template>
  <div class="home-header">
    <!-- 第一行 -->
    <div class="top">
      <div class="top2">
        <div class="topleft">
          <div class="ttwo">
            <a
              :href="about.twitter"
              @mouseover="mouseOver"
              @mouseleave="mouseLeave"
              target="_blank"
              ><img :src="tuite" alt=""
            /></a>
          </div>
          <div class="two1">
            <a
              :href="about.facebook"
              @mouseover="mouseOver1"
              @mouseleave="mouseLeave1"
              target="_blank"
              ><img :src="face" alt=""
            /></a>
          </div>
          <div class="two1">
            <a
              :href="about.instagram"
              @mouseover="mouseOver2"
              @mouseleave="mouseLeave2"
              target="_blank"
              ><img :src="im" alt=""
            /></a>
          </div>
        </div>
        <div class="topright">
          <div v-if="!token" class="tkshow">
            <div class="two" @click="showModal">
              {{ $t("login") }}
              <a-modal v-model="visible" width="390px">
                <div>
                  <a-tabs default-active-key="1" @change="callback">
                    <a-tab-pane key="1" :tab="$t('supplier')">
                      <div class="list">
                        <div class="one" style="font-size: 14px;">
                          {{ $t("Mobile.email") }}
                        </div>
                        <div class="account">
                          <input
                            type="text"
                            v-model="accountname"
                            @focus="qcac()"
                          />
                        </div>
                        <div class="acone" v-show="tag">
                          {{ $t("Account.number") }}
                        </div>
                        <div v-if="loginType == 1">
                          <div class="two2">{{ $t("code") }}</div>
                          <div class="code">
                            <input type="text" v-model="code" />
                            <button
                              type="button"
                              class="ant-btn"
                              @click="
                                () => {
                                  (e) => e.preventDefault();
                                  sendCode();
                                }
                              "
                            >
                              <span>{{
                                codetag == false ? $t("send") : count
                              }}</span>
                            </button>
                          </div>
                        </div>
                        <div v-else>
                          <div class="two2">{{ $t("Password") }}</div>
                          <div class="account">
                            <input type="password" v-model="password" />
                          </div>
                        </div>
                        <div class="logintype" @click="checktype()">
                          {{
                            loginType == 1
                              ? $t("Passwordlogin")
                              : $t("Vcodelogin")
                          }}>>
                        </div>
                        <button class="submit" @click="regsub()">
                          {{ $t("login") }}
                        </button>
                        <div class="acone" v-show="subtag">
                          {{ $t("correctly") }}
                        </div>
                      </div>
                    </a-tab-pane>
                    <a-tab-pane key="2" :tab="$t('buyers')" force-render>
                      <div class="list">
                        <div class="one">{{ $t("Mobile.email") }}</div>
                        <div class="account">
                          <input
                            type="text"
                            v-model="accountname"
                            @focus="qcac()"
                          />
                        </div>
                        <div class="acone" v-show="tag">
                          {{ $t("Account.number") }}
                        </div>
                        <div v-if="loginType == 1">
                          <div class="two2">{{ $t("code") }}</div>
                          <div class="code">
                            <input type="text" v-model="code" />
                            <button
                              type="button"
                              class="ant-btn"
                              @click="
                                () => {
                                  (e) => e.preventDefault();
                                  sendCode();
                                }
                              "
                            >
                              <span>{{
                                codetag == false ? $t("send") : count
                              }}</span>
                            </button>
                          </div>
                        </div>
                        <div v-else>
                          <div class="two2">{{ $t("Password") }}</div>
                          <div class="account">
                            <input type="password" v-model="password" />
                          </div>
                        </div>
                        <div class="logintype" @click="checktype()">
                          {{
                            loginType == 1
                              ? $t("Passwordlogin")
                              : $t("Vcodelogin")
                          }}>>
                        </div>
                        <button class="submit" @click="regsub()">
                          {{ $t("login") }}
                        </button>
                        <div class="acone" v-show="subtag">
                          {{ $t("correctly") }}
                        </div>
                      </div>
                    </a-tab-pane>
                  </a-tabs>
                </div>
              </a-modal>
            </div>
            <!-- <div><router-link to="/Login">{{ $t('supplier')}}</router-link></div>
            <div class="two"><router-link to="/Login">{{ $t('buyers')}}</router-link></div> -->
          </div>
          <div class="tkshow" v-if="token">
            <div>{{ account }}</div>
            <div class="two" @click="loginout()">{{ $t("signout") }}</div>
            <div class="two">
              <router-link
                :to="{ name: 'UsersIndex', params: { userId: userId } }"
                >{{ $t("userinfo") }}</router-link
              >
            </div>
          </div>
          <div class="two">
            <a-dropdown>
              <span
                class="ant-dropdown-link"
                @click="(e) => e.preventDefault()"
              >
                {{ $t("signUp") }}<a-icon type="down" />
              </span>
              <a-menu slot="overlay">
                <a-menu-item class="overlay">
                  <router-link
                    :to="{ name: 'Register', params: { categoryId: 1 } }"
                    >{{ $t("signUp.supplier") }}</router-link
                  >
                </a-menu-item>
                <a-menu-item class="overlay">
                  <router-link
                    :to="{ name: 'Register', params: { categoryId: 2 } }"
                    >{{ $t("signUp.buyer") }}</router-link
                  >
                </a-menu-item>
              </a-menu>
            </a-dropdown>
          </div>
          <div class="two">
            <!-- <a-dropdown>
              <span class="ant-dropdown-link">
              {{ $t('lang.language')}}<a-icon type="down" />
              </span>
              <a-menu slot="overlay">
                <a-menu-item @click="setLang('en-US')" class="overlay">
                  <a href="javascript:;">{{ $t('lang.english') }}</a>
                </a-menu-item>
                <a-menu-item @click="setLang('zh-CN')" class="overlay">
                  <a href="javascript:;">{{ $t('lang.chinese') }}</a>
                </a-menu-item>
              </a-menu>
            </a-dropdown> -->
            <img
              src="@/assets/images/chtag.png"
              alt=""
              height="25px"
              style="border-top-left-radius:5px;border-bottom-left-radius:5px;"
              @click="setLang('zh-CN')"
            />
            <img
              src="@/assets/images/entag.png"
              alt=""
              height="25px"
              style="border-top-right-radius:5px;border-bottom-right-radius:5px;"
              @click="setLang('en-US')"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- 第二行 -->
    <div class="search">
      <div class="logo">
        <div class="logoimg">
          <img :src="about.site_logo" alt="" />
        </div>
        <div class="searchinput">
          <input type="text" v-model="keywords" :placeholder="$t('trade')" />
          <a
            @click="
              () => {
                (e) => e.preventDefault();
                searchBtn();
              }
            "
          >
            <router-link
              :to="{ name: 'Category', params: { keywords: keywords } }"
              style="color: #F3F3F3"
              >{{ $t("search") }}</router-link
            ></a
          >
        </div>
      </div>
    </div>
    <!-- 第三行 -->
    <div class="cate">
      <div class="gory">
        <div
          class="ctwo"
          @click="
            () => {
              cateIsShow = !cateIsShow;
            }
          "
        >
          <a-icon type="unordered-list" style="fontSize:20px" />
          {{ $t("categories") }}
          <div class="pcate" v-show="cateIsShow">
            <template v-for="(item, index) in cates">
              <!-- 超过13个 -->
              <template v-if="cates.length > 13">
                <div
                  class="pcate1"
                  :key="index"
                  @mouseover="
                    () => {
                      item.childShow = !item.childShow;
                    }
                  "
                  @mouseout="
                    () => {
                      item.childShow = !item.childShow;
                    }
                  "
                  v-if="index < 12"
                >
                  <router-link
                    :to="{
                      name: 'Category',
                      params: { categoryId: item.categoryId },
                    }"
                    >{{ item.categoryName }}</router-link
                  >
                  <!-- 右侧 -->
                  <div class="pchild" v-show="item.childShow">
                    <div class="pchild1">
                      <div class="child1">{{ $t("industry") }}</div>
                      <div class="child2">
                        <div
                          class="child3"
                          v-for="(vo, key) in item.childCategory"
                          :key="key"
                        >
                          <router-link
                            :to="{
                              name: 'Category',
                              params: {
                                categoryId: item.categoryId,
                                childId: vo.categoryId,
                              },
                            }"
                            >{{ vo.categoryName }}</router-link
                          >
                        </div>
                      </div>
                    </div>
                    <div class="pchild1">
                      <div class="child1">{{ $t("data") }}</div>
                      <div class="child2">
                        <div
                          class="child3"
                          v-for="(vo, key) in dates"
                          :key="key"
                        >
                          <router-link
                            :to="{
                              name: 'Category',
                              params: {
                                categoryId: item.categoryId,
                                date: vo.startMonthValue,
                              },
                            }"
                            >{{ vo.startMonthValue }}</router-link
                          >
                        </div>
                      </div>
                    </div>
                    <div class="pchild1">
                      <div class="child1">{{ $t("area") }}</div>
                      <div class="child2">
                        <div
                          class="child3"
                          v-for="(vo, key) in countries"
                          :key="key"
                        >
                          <router-link
                            :to="{
                              name: 'Category',
                              params: {
                                categoryId: item.categoryId,
                                countryId: vo.countryId,
                              },
                            }"
                            >{{ vo.countryName }}</router-link
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <template v-else>
                <div
                  class="pcate1"
                  :key="index"
                  @mouseover="
                    () => {
                      item.childShow = !item.childShow;
                    }
                  "
                  @mouseout="
                    () => {
                      item.childShow = !item.childShow;
                    }
                  "
                >
                  <router-link
                    :to="{
                      name: 'Category',
                      params: { categoryId: item.categoryId },
                    }"
                    >{{ item.categoryName }}</router-link
                  >
                  <!-- 右侧 -->
                  <div class="pchild" v-show="item.childShow">
                    <div class="pchild1">
                      <div class="child1">Industry</div>
                      <div class="child2">
                        <div
                          class="child3"
                          v-for="(vo, key) in item.childCategory"
                          :key="key"
                        >
                          <router-link
                            :to="{
                              name: 'Category',
                              params: {
                                categoryId: item.categoryId,
                                childId: vo.categoryId,
                              },
                            }"
                            >{{ vo.categoryName }}</router-link
                          >
                        </div>
                      </div>
                    </div>
                    <div class="pchild1">
                      <div class="child1">Date</div>
                      <div class="child2">
                        <div
                          class="child3"
                          v-for="(vo, key) in dates"
                          :key="key"
                        >
                          <router-link
                            :to="{
                              name: 'Category',
                              params: {
                                categoryId: item.categoryId,
                                date: vo.startMonthValue,
                              },
                            }"
                            >{{ vo.startMonthValue }}</router-link
                          >
                        </div>
                      </div>
                    </div>
                    <div class="pchild1">
                      <div class="child1">Area</div>
                      <div class="child2">
                        <div
                          class="child3"
                          v-for="(vo, key) in countries"
                          :key="key"
                        >
                          <router-link
                            :to="{
                              name: 'Category',
                              params: {
                                categoryId: item.categoryId,
                                countryId: vo.countryId,
                              },
                            }"
                            >{{ vo.countryName }}</router-link
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </template>
            <!-- 超过13个 -->
            <template v-if="cates.length > 13">
              <div class="more">
                <router-link :to="{ name: 'Category' }"
                  >更多&gt;&gt;</router-link
                >
              </div>
            </template>
          </div>
        </div>
        <div
          :class="
            cateIsShow ? 'ctwo' : routerName == 'Home' ? 'cone active' : 'cone'
          "
        >
          <router-link to="/">
            {{ $t("home") }}
          </router-link>
        </div>
        <div
          :class="
            cateIsShow
              ? 'ctwo'
              : routerName == 'Category'
              ? 'cone active'
              : 'cone'
          "
        >
          <router-link to="/category/status">
            {{ $t("TradeFair") }}
          </router-link>
        </div>
        <div
          :class="
            cateIsShow
              ? 'ctwo'
              : routerName == 'Service'
              ? 'cone active'
              : 'cone'
          "
        >
          <!-- <router-link to="">
            {{ $t("Webinar") }}
          </router-link> -->
          <a @click="updateing">{{ $t("Webinar") }}</a>
        </div>
        <div
          :class="
            cateIsShow ? 'ctwo' : routerName == '' ? 'cone active' : 'cone'
          "
        >
          <!-- <router-link to="">
            {{ $t("HybridExpo") }}
          </router-link> -->
          <a @click="updateing">{{ $t("HybridExpo") }}</a>
        </div>
        <div
          :class="
            cateIsShow
              ? 'ctwo'
              : routerName == 'HomeNews'
              ? 'cone active'
              : 'cone'
          "
        >
          <router-link to="/homenews">
            {{ $t("new") }}
          </router-link>
        </div>
        <div
          :class="
            cateIsShow ? 'ctwo' : routerName == 'AboutUs' ? 'cone active' : 'cone'
          "
        >
          <router-link to="/aboutUs">
            {{ $t("about") }}
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import storage from "store";

export default {
  inject: ["reload"], // 注入依赖
  data() {
    return {
      language: "",
      keywords: "",
      routerName: this.$router.currentRoute.name,
      cateIsShow: false,
      childIsShow: false,
      countries: [],
      dates: [],
      cates: [],
      token: localStorage.token,
      account: localStorage.account,
      userId: localStorage.userId,
      // token: storage.get(ACCESS_TOKEN),
      // account: storage.get('account'),
      // userId: storage.get('userId')
      loading: false,
      visible: false,
      categoryId: 1,
      accountname: "",
      show: true,
      count: "",
      timer: null,
      tag: false,
      subtag: false,
      code: "",
      about: [],
      loginType: 2,
      password: "",
      tuite: require("@/assets/images/twitter.png"),
      face: require("@/assets/images/facebook.png"),
      im: require("@/assets/images/ing2.png"),
      codetag: false,
    };
  },
  mounted() {
    if (storage.get("lang")) this.language = storage.get("lang");
    else this.language = "en-US";
    this.getCountry();
    this.getDate();
    this.getCate();
    this.getAbout();
    this.isLogin();
  },
  methods: {
    updateing () {
      this.$layer.msg(this.$t("updating"));
    },
    setLang(lang) {
      if (lang != this.language) {
        this.$store.dispatch("setLang", lang);
        this.$nextTick(() => {
          this.reload();
        });
      }
    },
    // 搜索
    searchBtn() {
      console.log(this.keywords);
    },
    // 获取国家
    getCountry() {
      this.$common
        .fetchList("/exhibition/condition/country", {})
        .then((data) => {
          if (data.code === 200) {
            const result = data.data;
            this.countries = result;
          }
        })
        .catch(() => {
          this.$notification.error({
            message: "错误",
            description: "请求信息失败，请重试",
          });
        });
    },
    // 获取日期
    getDate() {
      this.$common
        .fetchList("/exhibition/condition/date", {})
        .then((data) => {
          if (data.code === 200) {
            const result = data.data;
            this.dates = result;
          }
        })
        .catch(() => {
          this.$notification.error({
            message: "错误",
            description: "请求信息失败，请重试",
          });
        });
    },
    // 获取分类
    getCate() {
      this.$common
        .fetchList("/index/category/lists", {})
        .then((data) => {
          if (data.code === 200) {
            const result = data.data;
            result.forEach((item) => {
              this.cates.push({
                categoryId: item.categoryId,
                categoryName: item.categoryName,
                childCategory: item.childCategory,
                childShow: false,
              });
            });
          }
        })
        .catch(() => {
          this.$notification.error({
            message: "错误",
            description: "请求信息失败，请重试",
          });
        });
    },
    //退出
    loginout() {
      this.$common
        .handleGet("/user/logout", {})
        .then((data) => {
          if (data.code === 200) {
            localStorage.setItem("token", "");
            this.$layer.alert(data.msg);
            this.reload();
          }
        })
        .catch(() => {
          this.$layer.alert(data.msg);
          this.$notification.error({
            message: "错误",
            description: "请求信息失败，请重试",
          });
        });
    },
    showModal() {
      this.visible = true;
    },
    handleOk(e) {
      this.loading = true;
    },
    handleCancel(e) {
      this.visible = false;
    },
    callback(key) {
      this.categoryId = key;
      console.log(this.categoryId);
    },
    sendCode() {
      if (this.accountname == "") {
        this.tag = true;
        return;
      }
      this.codetag = true;
      const TIME_COUNT = 60;
      if (!this.timer) {
        this.count = TIME_COUNT;
        this.show = false;
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--;
          } else {
            this.show = true;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);
      }
      if (this.count == 0) {
        this.codetag = false;
      }
      const params = {
        account: this.accountname,
        type: "login",
      };
      this.$common
        .handlePost("/code/send", params)
        .then((data) => {
          if (data.code === 200) {
            // this.$layer.alert(data.msg);
          }
        })
        .catch(() => {
          // this.$notification.error({
          // message: '错误',
          // description: '请求信息失败，请重试'
          // })
        });
    },
    //去除提示信息
    qcac() {
      this.tag = false;
      this.subtag = false;
    },
    //登录
    regsub() {
      if (this.loginType == 1) {
        if (this.accountname == "" || this.code == "") {
          this.subtag = true;
          return;
        }
      } else {
        if (this.accountname == "" || this.password == "") {
          this.subtag = true;
          return;
        }
      }
      const params = {
        account: this.accountname,
        password: this.password,
        categoryId: this.categoryId,
        loginType: this.loginType,
        code: this.code,
      };
      this.$common
        .handlePost("/user/login", params)
        .then((data) => {
          if (data.code === 200) {
            console.log(data);
            this.$layer.alert(data.msg);
            localStorage.setItem("token", data.data.token);
            localStorage.setItem("account", data.data.account);
            localStorage.setItem("userId", data.data.userId);
            localStorage.setItem("categoryId", data.data.categoryId);
            localStorage.setItem("companyId", data.data.companyId);
            this.reload();
            // storage.set(ACCESS_TOKEN, data.data.token, 7 * 24 * 60 * 60 * 1000)
            // storage.set('account', data.data.account, 7 * 24 * 60 * 60 * 1000)
            // storage.set('userId', data.data.userId, 7 * 24 * 60 * 60 * 1000)
            // this.$router.push({name: 'Home'})
          } else {
            // this.$layer.alert(data.msg);
            this.$layer.msg(data.msg);
            this.accountname = "";
            this.password = "";
          }
        })
        .catch(() => {
          // this.$notification.error({
          // message: '错误',
          // description: '请求信息失败，请重试'
          // })
        });
      setTimeout(() => {
        this.visible = false;
        this.loading = false;
      }, 1000);
    },
    getAbout() {
      this.$common
        .fetchList("/index/about/us", {})
        .then((data) => {
          if (data.code === 200) {
            const result = data.data;
            this.about = result;
          }
        })
        .catch(() => {
          // this.$notification.error({
          //   message: '错误',
          //   description: '请求信息失败，请重试'
          // })
        });
    },
    //判断token是否失效
    isLogin() {
      this.$common
        .fetchList("/user/is/login?token=" + this.token, {})
        .then((data) => {
          if (data.code !== 200) {
            localStorage.setItem("token", "");
            localStorage.setItem("account", "");
            localStorage.setItem("userId", "");
            localStorage.setItem("categoryId", "");
            localStorage.setItem("companyId", "");
          }
        })
        .catch(() => {
          // this.$notification.error({
          //   message: '错误',
          //   description: '请求信息失败，请重试'
          // })
        });
    },
    //更改登录方式
    checktype() {
      if (this.loginType == 2) {
        this.loginType = 1;
      } else {
        this.loginType = 2;
      }
    },
    //移入改变图片
    mouseOver() {
      this.tuite = require("@/assets/images/tuite.png");
    },
    mouseLeave() {
      this.tuite = require("@/assets/images/twitter.png");
    },
    //移入改变图片
    mouseOver1() {
      this.face = require("@/assets/images/face2.png");
    },
    mouseLeave1() {
      this.face = require("@/assets/images/facebook.png");
    },
    //移入改变图片
    mouseOver2() {
      this.im = require("@/assets/images/in.png");
    },
    mouseLeave2() {
      this.im = require("@/assets/images/ing2.png");
    },
  },
};
</script>

<style lang="less" scoped>
@import "~@/assets/css/header";
.two {
  display: block;
}
</style>
