import axios from 'axios'
import storage from 'store'
import notification from 'ant-design-vue/es/notification'
import { VueAxios } from './axios'
import { ACCESS_TOKEN, API_DOMAIN } from '@/store/mutation-types'
// import common from '@/utils/common'

// 创建 axios 实例
const request = axios.create({
  // API 请求的默认前缀
  baseURL: API_DOMAIN,
  timeout: 600000 // 请求超时时间
})

// 异常拦截处理器
const errorHandler = (error) => {
  if (error.response) {
    /*
    const data = error.response.data
    // 从 localstorage 获取 token
    const token = storage.get(ACCESS_TOKEN)
    if (error.response.status === 403) {
      notification.error({
        message: 'Forbidden',
        description: data.message
      })
    }
    if (error.response.status === 401 && !(data.result && data.result.isLogin)) {
      notification.error({
        message: 'Unauthorized',
        description: 'Authorization verification failed'
      })
      if (token) {
        store.dispatch('Logout').then(() => {
          setTimeout(() => {
            window.location.reload()
          }, 1500)
        })
      }
    }*/
  }
  return Promise.reject(error)
}

// request interceptor
request.interceptors.request.use(config => {  
  // const token = storage.get(ACCESS_TOKEN)
  const token = localStorage.token
  // const token = '6683de21-3e24-4610-e4ef-6dc244605f74'
  // 如果 token 存在
  if (token) {
    config.headers['Authorization'] = 'Bearer ' + token
  }
  const lang = storage.get('lang')
  if (lang) {
    config.headers['lang'] = 'Bearer ' + lang
  }
  return config
}, errorHandler)

// response interceptor
request.interceptors.response.use((response) => {
  /*
  const token = storage.get(ACCESS_TOKEN)
  // 验签失败
  if (response.data.code === 510) {
    notification.error({
      message: response.data.msg,
      description: response.data.msg
    })
    if (token) {
      storage.remove(ACCESS_TOKEN)
    }
    setTimeout(() => {
      location.href = '/user'
    }, 1500)
  }
  // 请先登陆
  if (response.data.code === 401) {
    notification.error({
      message: '请先登录',
      description: '请先登录'
    })
    if (token) {
      storage.remove(ACCESS_TOKEN)
    }
    setTimeout(() => {
      location.href = '/user'
    }, 1500)
  }
  // 管理员不存在
  if (response.data.code === 501) {
    notification.error({
      message: '请先登录',
      description: '请先登录'
    })
    if (token) {
      storage.remove(ACCESS_TOKEN)
    }
    setTimeout(() => {
      location.href = '/user'
    }, 1500)
  }*/
  return response.data
}, errorHandler)

const installer = {
  vm: {},
  install (Vue) {
    Vue.use(VueAxios, request)
  }
}

export default request

export {
  installer as VueAxios,
  request as axios
}
