export const ACCESS_TOKEN = 'Access-Token'

export const SIDEBAR_TYPE = 'sidebar_type'
export const TOGGLE_MOBILE_TYPE = 'is_mobile'
export const TOGGLE_NAV_THEME = 'nav_theme'
export const TOGGLE_LAYOUT = 'layout'
export const TOGGLE_FIXED_HEADER = 'fixed_header'
export const TOGGLE_FIXED_SIDEBAR = 'fixed_sidebar'
export const TOGGLE_CONTENT_WIDTH = 'content_width'
export const TOGGLE_HIDE_HEADER = 'auto_hide_header'
export const TOGGLE_COLOR = 'color'
export const TOGGLE_WEAK = 'weak'
export const TOGGLE_MULTI_TAB = 'multi_tab'
export const APP_LANGUAGE = 'app_language'

export const CONTENT_WIDTH_TYPE = {
  Fluid: 'Fluid',
  Fixed: 'Fixed'
}

export const NAV_THEME = {
  LIGHT: 'light',
  DARK: 'dark'
}

// export const API_DOMAIN = process.env.NODE_ENV === 'production' && process.env.VUE_APP_PREVIEW !== 'true' ? 'https://api.e2xpo.com/home' : 'http://api.e2xpo.softwarechina.cn/home'

// export const SOURCE_API_DOMAIN = process.env.NODE_ENV === 'production' && process.env.VUE_APP_PREVIEW !== 'true' ? 'https://api.e2xpo.com/admin' : 'http://api.e2xpo.softwarechina.cn/admin'

export const API_DOMAIN = process.env.NODE_ENV === 'production' && process.env.VUE_APP_PREVIEW !== 'true' ? 'https://api.e2xpo.com/home' : 'http://api.e2xpo.com/home'

export const SOURCE_API_DOMAIN = process.env.NODE_ENV === 'production' && process.env.VUE_APP_PREVIEW !== 'true' ? 'https://api.e2xpo.com/admin' : 'http://api.e2xpo.com/admin'