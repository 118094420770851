<template>
  <a-config-provider :locale="locale">
    <div id="app">
      <router-view v-if="isRouterAlive" />
    </div>
  </a-config-provider>
</template>

<script>
import { i18nRender } from '@/locales'
export default {
  provide () { // 父组件中通过provide来提供变量，在子组件中通过inject来注入变量。
    return {
      reload: this.reload
    }
  },
  data () {
    return {
      isRouterAlive: true // 控制视图是否显示的变量
    }
  },
  computed: {
    locale () {
      return this.$i18n.getLocaleMessage(this.$store.getters.lang).antLocale
    }
  },
  mounted() {
    this.handleFilterGray()
  },
  methods: {
    reload () {
      this.isRouterAlive = false // 先关闭
      this.$nextTick(function () {
        this.isRouterAlive = true // 再打开
      })
    },
    // 整站黑白色
    handleFilterGray() {
      // 设定目标日期
      let targetDate = new Date('2023-11-03 23:59:59');  
    
      // 获取当前日期
      let currentDate = new Date();

      const dom = document.querySelector('body')

      // 比较两个日期
      if(currentDate > targetDate) {  
        dom.classList.remove('filterGray')
      } else {  
        dom.classList.add('filterGray')
      }
    }
  }
}
</script>

<style lang="scss">
// 整站灰色
// html{
//   filter: grayscale(100%);
//   -webkit-filter: grayscale(100%);
//   -moz-filter: grayscale(100%);
//   -ms-filter: grayscale(100%);
//   -o-filter: grayscale(100%);
// }
.filterGray{
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100%;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
