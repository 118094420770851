export default {
  'user.login.userName': '用户名',
  'user.login.password': '密码',
  'user.login.username.placeholder': '账户: admin',
  'user.login.password.placeholder': '密码: admin or ant.design',
  'user.login.message-invalid-credentials': '账户或密码错误（admin/ant.design）',
  'user.login.message-invalid-verification-code': '验证码错误',
  'user.login.tab-login-credentials': '账户密码登录',
  'user.login.tab-login-mobile': '手机号登录',
  'user.login.mobile.placeholder': '手机号',
  'user.login.mobile.verification-code.placeholder': '验证码',
  'user.login.remember-me': '自动登录',
  'user.login.forgot-password': '忘记密码',
  'user.login.sign-in-with': '其他登录方式',
  'user.login.signup': '注册账户',
  'user.login.login': '登录',
  'user.register.register': '注册',
  'user.register.email.placeholder': '邮箱',
  'user.register.password.placeholder': '请至少输入 6 个字符。请不要使用容易被猜到的密码。',
  'user.register.password.popover-message': '请至少输入 6 个字符。请不要使用容易被猜到的密码。',
  'user.register.confirm-password.placeholder': '确认密码',
  'user.register.get-verification-code': '获取验证码',
  'user.register.sign-in': '使用已有账户登录',
  'user.register-result.msg': '你的账户：{email} 注册成功',
  'user.register-result.activation-email':
    '激活邮件已发送到你的邮箱中，邮件有效期为24小时。请及时登录邮箱，点击邮件中的链接激活帐户。',
  'user.register-result.back-home': '返回首页',
  'user.register-result.view-mailbox': '查看邮箱',
  'user.email.required': '请输入邮箱地址！',
  'user.email.wrong-format': '邮箱地址格式错误！',
  'user.userName.required': '请输入帐户名或邮箱地址',
  'user.password.required': '请输入密码！',
  'user.password.twice.msg': '两次输入的密码不匹配!',
  'user.password.strength.msg': '密码强度不够 ',
  'user.password.strength.strong': '强度：强',
  'user.password.strength.medium': '强度：中',
  'user.password.strength.low': '强度：低',
  'user.password.strength.short': '强度：太短',
  'user.confirm-password.required': '请确认密码！',
  'user.phone-number.required': '请输入正确的手机号',
  'user.phone-number.wrong-format': '手机号格式错误！',
  'user.verification-code.required': '请输入验证码！',
  'user.account': '账号',
  'user.current-account': '当前账号',
  'user.yzm': '验证码',
  'user.new_pass': '新密码',
  'user.confirm_pass': '确认密码',
  'user.get_yzm': '获取验证码',
  'user.center': '个人中心',
  // 公司基本信息
  'user.info': '公司基本信息',
  'user.info.en': '公司英文名称',
  'user.info.zh': '公司中文名称',
  'user.info.area': '公司所在区域',
  'user.info.select-country': '请选择国家',
  'user.info.address-zh': '中文具体地址',
  'user.info.address-en': '英文具体地址',
  'user.info.company-tel': '公司电话',
  'user.info.website': '官网网址',
  'user.info.contact-tel': '联系电话',
  'user.info.fax': '传真',
  'user.info.contact': '联系人信息（账户）',
  'user.info.first-name': '名',
  'user.info.last-name': '姓',
  'user.info.chinese-name': '中文名',
  'user.info.gender': '性别',
  'user.info.gender-0': '未知',
  'user.info.gender-1': '男',
  'user.info.gender-2': '女',
  'user.info.tel': '电话',
  'user.info.position': '职位',
  'user.info.email': '邮箱',
  'user.info.wechat': '微信',
  'user.info.business': '公司业务信息',
  'user.info.main': '主营业务',
  'user.info.main-zh': '主营业务(中文)',
  'user.info.main-en': '主营业务(英文)',
  'user.info.desc-zh': '公司描述(中文)',
  'user.info.desc-en': '公司描述(英文)',
  'user.info.qualification': '公司资质图片',
  'user.info.qualification-upload': '上传资质图片',
  'user.info.company-logo': '公司logo',
  'user.info.company-logo-upload': '上传logo',
  'user.info.company-img': '公司形象图',
  'user.info.company-img-upload': '上传形象图',
  'user.info.index-banner': '展会首页banner图',
  'user.info.index-banner-upload': '上传banner',
  'user.info.company-video': '公司视频',
  'user.info.company-video-upload': '上传视频',
  'user.info.pro-category': '产品分类',
  'user.info.price-terms': '价格术语',
  'user.edit': '编辑',
  'user.save': '保存',
  'user.add': '新增',
  'user.delete': '删除',
  'user.save': '保存',
  'user.cancel': '取消',
  'user.number': '序号',
  'user.audit-status': '审核状态',
  'user.info.account': '账号',
  'user.operation': '操作',
  'user.edit3d': '编辑3D',
  'user.on': '上架',
  'user.off': '下架',
  'user.detail': '详情',
  'user.area': '地区',
  'user.picture': '图片',
  'user.picture-upload': '上传图片',
  'user.yes': '是',
  'user.no': '否',
  'user.keywords': '关键字',
  'user.keywords-zh': '关键字(中文)',
  'user.keywords-en': '关键字(英文)',
  'user.keywords-more': '多个关键字以“|”隔开',
  'user.base-info': '基本信息',
  'user.contact-info': '联系方式',
  'user.product.name': '产品名称',
  'user.product.name-zh': '产品名称(中文)',
  'user.product.name-en': '产品名称(英文)',
  'user.product.category-one': '一级分类',
  'user.product.category-two': '二级分类',
  'user.product.max-price': '最高价',
  'user.product.min-price': '最低价',
  'user.product.min-order': '最小起订量',
  'user.product.quantity': '产品数量',
  'user.product.keywords': '产品关键字',
  'user.product.desc-zh': '产品描述(中文)',
  'user.product.desc-en': '产品描述(英文)',
  'user.product.min-price1': '最低价格',
  'user.product.max-price1': '最高价格',
  'user.product.pay-method': '支付方式(简写)',
  'user.product.img': '产品封面图',
  'user.product.imgs': '产品图片集',
  'user.product.imgs-upload': '上传图片集',
  'user.product.videos': '产品视频集',
  'user.inquiry-title': '询价标题',
  'user.purchaser.info': '采购商公司信息',
  'user.purchaser.company-name': '采购商公司名称',
  'user.buy-number': '采购量',
  'user.inquiry-status': '询价状态',
  'user.rfq.info': 'rfq信息',
  'user.rfq.title': 'rfq标题',
  'user.rfq.status': 'rfq状态',
  'user.rfq.price-info': 'rfq报价信息',
  'user.rfq.price': 'rfq报价',
  'user.rfq.desc': 'rfq描述',
  'user.effective-date': '有效日期',
  'user.pay-type': '支付方式',
  'user.delivery-place': '交货地点',
  'user.select-cate': '请选择分类',
  'user.budget': '预算',
  'user.attachment': '附件',
  'user.attachment-upload': '上传附件',
  'user.submit': '提交',
  'user.company-name': '公司名称',
  'user.company-addr': '公司地址',
  'user.company-area': '公司区域',
  'user.desc': '描述',
  'user.price': '价格',
  'user.status': '状态',
  'user.select': '选择',
  'user.check': '查看详情',
  'user.send-message': '发送信息',
  'user.attachment-info': 'rfq附件文件信息',
  'user.meeting.type': '会议类型',
  'user.meeting.addr': '会议地址',
  'user.meeting.startTime': '会议开始时间',
  'user.meeting.endTime': '会议结束时间',
  'user.meeting.status': '会议状态',
  'user.meeting.supplier-company-name': '供应商公司名称',
  'user.meeting.isaccept': '是否接受邀请',
  'user.meeting.accept': '接受',
  'user.meeting.accept-status': '配对成功',
  'user.meeting.decline': '拒绝',
  'user.meeting.decline-status': '配对失败',
  'user.meeting.handle-accept-status': '确定要接受吗？',
  'user.meeting.handle-decline-status': '确定要拒绝吗？',
  'user.meeting.purchaser': '采购方信息',
  'user.meeting.chat': '聊天',
  'user.meeting.start': '开始会议',
  'user.meeting.startup': '启动',
  'user.questionnaire.title': '问卷标题',
  'user.questionnaire.status': '问卷状态',
  'user.questionnaire.fill': '是否填写问卷',
  'user.startTime': '开始时间',
  'user.endTime': '结束时间',
  'user.exhibition.info': '展会基本信息',
  'user.exhibition.contact-product': '展会关联商品',
  'user.exhibition.cancel-contact': '取消关联',
  'user.exhibition.no-contact-product': '展会未关联商品',
  'user.exhibition.contact': '关联',
  'user.sort': '排序',
  'user.isOnShelf': '是否上架',
  'user.video.title': '视频标题',
  'user.video.title-en': '视频标题（英文）',
  'user.video.img': '视频封面图',
  'user.video.img-upload': '上传封面图',
  'user.video': '视频',
  'user.video-upload': '上传视频',
  'user.disable': '禁用',
  'user.enable': '启用',
  'user.wait-check': '待审核',
}
