import Vue from 'vue'
import moment from 'moment'
import 'moment/locale/zh-cn'
moment.locale('zh-cn')

Vue.filter('NumberFormat', function (value) {
  if (!value) {
    return '0'
  }
  const intPartFormat = value.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') // 将整数部分逢三一断
  return intPartFormat
})

Vue.filter('dayjs', function (dataStr, pattern = 'YYYY-MM-DD HH:mm:ss') {
  return moment(dataStr).format(pattern)
})

Vue.filter('moment', function (dataStr, pattern = 'YYYY-MM-DD HH:mm:ss') {
  return moment(dataStr).format(pattern)
})

// 字符串截取
Vue.filter('cutstring', function (dataStr, len = 20) {
  // length属性读出来的汉字长度为1
  if (dataStr) {
    if (dataStr.length * 2 <= len) {
      return dataStr
    }
    var strlen = 0
    var s = ''
    for (var i = 0; i < dataStr.length; i++) {
      s = s + dataStr.charAt(i)
      if (dataStr.charCodeAt(i) > 128) {
        strlen = strlen + 2
        if (strlen >= len) {
          return s.substring(0, s.length - 1) + '...'
        }
      } else {
        strlen = strlen + 1
        if (strlen >= len) {
          return s.substring(0, s.length - 2) + '...'
        }
      }
    }
    return s
  }
})

// 去掉html标签
Vue.filter('removeHtml', function (dataStr) {
  console.log(dataStr)
  return dataStr && dataStr.replace(/<(?:.|\n)*?>/gm, '')
  .replace(/(&rdquo;)/g, '\"')
  .replace(/&ldquo;/g, '\"')
  .replace(/(&lsquo;)/g, '‘')
  .replace(/&rsquo;/g, '’')
  .replace(/&mdash;/g, '-')
  .replace(/&nbsp;/g, '')
  .replace(/&gt;/g, '>')
  .replace(/&lt;/g, '<')
  .replace(/&amp;/g, '&')
  .replace(/<[\w\s"':=\/]*/, '')
})
