import request from '@/utils/request'
import message from 'ant-design-vue/es/message'
import modal from 'ant-design-vue/es/modal'
// import { JSEncrypt } from 'jsencrypt'
import { SOURCE_API_DOMAIN } from '@/store/mutation-types'

export default {
    // 获取列表
    fetchList (url, params) {
        return new Promise((resolve, reject) => {
            request({
                url: url,
                method: 'get',
                params: {
                    ...params
                },
                type: 'json'
            }).then(data => {
                resolve(data)
            }).catch(error => {
                reject(error)
            })
        })
    },
    // 获取信息详情
    getInfo (url) {
        return new Promise((resolve, reject) => {
            request({
                url: url,
                method: 'get',
                type: 'json'
            }).then(data => {
                resolve(data)
            }).catch(error => {
                reject(error)
            })
        })
    },
    // 通过get获取信息
    handleGet (url, params) {
        return new Promise((resolve, reject) => {
            request({
                url: url,
                method: 'get',
                params: {
                    ...params
                },
                type: 'json'
            }).then(data => {
                resolve(data)
            }).catch(error => {
                reject(error)
            })
        })
    },
    // 通过post获取信息
    handlePost (url, params) {
        return new Promise((resolve, reject) => {
            request({
                url: url,
                method: 'post',
                data: {
                    ...params
                },
                type: 'json'
            }).then(data => {
                resolve(data)
            }).catch(error => {
                reject(error)
            })
        })
    },
    // 改变某些字段值
    changeField (url, params) {
        return new Promise((resolve, reject) => {
            request({
                url: url,
                method: 'post',
                data: params,
                type: 'json'
            }).then(data => {
                resolve(data)
            }).catch(error => {
                reject(error)
            })
        })
    },
    // 删除数据 - get(一条)
    deleteOne (url, id) {
        return new Promise((resolve, reject) => {
            if (id) {
                modal.confirm({
                    title: '确定要删除吗?',
                    onOk () {
                        request({
                            url: url,
                            method: 'get',
                            type: 'json'
                        }).then(data => {
                            if (data.code === 200) {
                                message.success('删除成功')
                                resolve(true)
                            } else {
                                message.error(data.msg)
                                resolve(false)
                            }
                        }).catch(error => {
                            reject(error)
                        })
                    },
                    onCancel () {
                        console.log('Cancel')
                    },
                    class: 'test'
                })
            } else {
                message.error('请选择要删除的内容')
            }
        })
    },
    // 删除数据 - post(一条或多条)
    delete (url, params, deleteIds) {
        return new Promise((resolve, reject) => {
            if (deleteIds.length > 0) {
                modal.confirm({
                    title: '确定要删除吗?',
                    onOk () {
                        request({
                            url: url,
                            method: 'post',
                            type: 'json',
                            data: params
                        }).then(data => {
                            if (data.code === 200) {
                                message.success('删除成功')
                                resolve(true)
                            } else {
                                message.error(data.msg)
                                resolve(false)
                            }
                        }).catch(error => {
                            reject(error)
                        })
                    },
                    onCancel () {
                        console.log('Cancel')
                    },
                    class: 'test'
                })
            } else {
                message.error('请选择要删除的内容')
            }
        })
    },
    // 上传图片
    uploadImageFile (formData) {
        return new Promise((resolve, reject) => {
            request({
                url: SOURCE_API_DOMAIN + '/files/image/upload',
                method: 'post',
                data: formData
            }).then(data => {
                resolve(data)
            }).catch(error => {
                reject(error)
            })
        })
    },
    // 上传视频
    uploadVideoFile (formData) {
        return new Promise((resolve, reject) => {
            request({
                url: SOURCE_API_DOMAIN + '/files/video/upload',
                method: 'post',
                data: formData
            }).then(data => {
                resolve(data)
            }).catch(error => {
                reject(error)
            })
        })
    },
    // 上传附件
    uploadFile (formData) {
        return new Promise((resolve, reject) => {
            request({
                url: SOURCE_API_DOMAIN + '/files/file/upload',
                method: 'post',
                data: formData
            }).then(data => {
                resolve(data)
            }).catch(error => {
                reject(error)
            })
        })
    },
    // 上传音频
    uploadAudioFile (formData) {
        return new Promise((resolve, reject) => {
            request({
                url: '/file/audio/upload',
                method: 'post',
                data: formData
            }).then(data => {
                resolve(data)
            }).catch(error => {
                reject(error)
            })
        })
    },
    getMonthDays (nowyear, lastMonth) {
        var lastMonthStartDate = new Date(nowyear, lastMonth, 1)
        var lastMonthEndDate = new Date(nowyear, lastMonth + 1, 1)
        var days = (lastMonthEndDate - lastMonthStartDate) / (1000 * 60 * 60 * 24) // 格式转换
        return days
    },
    // 日期处理 今天、昨天、本周、本月
    handleDate (type) {
        var start = ''
        var end = ''
        if (type) {
            var now = new Date() // 当前日期
            var nowDayOfWeek = now.getDay() // 今天本周的第几天
            var nowDay = now.getDate() // 当前日
            var nowMonth = now.getMonth() // 当前月
            var nowYear = now.getYear() // 当前年
            nowYear += (nowYear < 2000) ? 1900 : 0
            var lastMonthDate = new Date() // 上月日期
            var lastMonth = lastMonthDate.getMonth()
            if (type === 'today') { // 今日
                    start = new Date(new Date(new Date().toLocaleDateString()).getTime()) // 当天0点
                    end = new Date(new Date(new Date().toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000 - 1) // 当天23:59
            } else if (type === 'yesterday') { // 昨日
                    start = new Date(new Date(new Date().toLocaleDateString()).getTime() - 24 * 60 * 60 * 1000) // 当天0点
                    end = new Date(new Date(new Date().toLocaleDateString()).getTime() - 1) // 当天23:59
            } else if (type === 'week') { // 本周
                    start = new Date(nowYear, nowMonth, nowDay - nowDayOfWeek + 1)
                    end = new Date(new Date(nowYear, nowMonth, nowDay + (7 - nowDayOfWeek)).getTime() + 24 * 60 * 60 * 1000 - 1)
            } else if (type === 'month') { // 本月
                    start = new Date(nowYear, nowMonth, 1)
                    var days = this.getMonthDays(nowMonth, lastMonth) // 获取当月总共有多少天
                    end = new Date(new Date(nowYear, nowMonth, days).getTime() + 24 * 60 * 60 * 1000 - 1)
            }
        }
        return {
            start: start,
            end: end
        }
    },
    // 获取年份
    getYear () {
        const myDate = new Date()
        return myDate.getFullYear()
    },
    // 获取月
    getMonth () {
        const myDate = new Date()
        return myDate.getMonth() + 1
    },
    // 获取日
    getDay () {
        const myDate = new Date()
        return myDate.getDate()
    },
    // 自适应字体大小
    setHtmlFontSize () {
        // 1920是设计稿的宽度，当大于1366时采用1366宽度，比例也是除以13.66
        const deviceWidth = document.documentElement.clientWidth > 1920 ? 1920 : document.documentElement.clientWidth
        document.getElementsByTagName('html')[0].style.cssText = 'font-size:' + deviceWidth / 19.20 + 'px !important'
    },
    /**
     * 组建 a-tree-select
     * 树选择框,构造转换，转换后端数据为树形选择需要的数据
     * @param data  后端数据
     * @param name  后端数据 - 显示的文字字段名称
     * @param id    后端数据 - 主键字段名称
     * @returns {[]}  返回结构
     */
    convertToTreedata (data, name, id) {
        // console.log(data, '数据构造........')
        var returnData = []
        // 遍历数据
        for (var i = 0; i < data.length; i++) {
            var tempObj = {
                title: data[i][name],
                value: data[i][id],
                key: data[i][id]
            }
            // 判断是否存在子节点，如果存在则递归
            if ('children' in data[i]) {
                tempObj.children = this.convertToTreedata(data[i].children)
            }
            // push到数据数组中
            returnData.push(tempObj)
        }
        return returnData
    },
    // 获取加密处理后的请求头参数
    getRequestHeader (publicKey) {
        var params = {
            nonce: Math.floor((Math.random() + Math.floor(Math.random() * 9 + 1)) * Math.pow(10, 11)), // 生成10位随机数 Math.floor((Math.random()+Math.floor(Math.random()*9+1))*Math.pow(10,num-1));
            echostr: 'backend',
            timestamp: new Date().getTime()
        }
        const str = this.objectToString(params)
        if (str) {
            const signature = this.getSignature(str, publicKey)
            params.signature = signature
        }
        return params
    },
    // 将对象组成 echostr=android&nonce=0.884657372334232&timestamp=1623377537067
    objectToString (params) {
      const paramsNew = ksort(params) // 键名排序
      const paramsArr = Object.entries(paramsNew)
      var arr = []
      for (const [k, v] of paramsArr) {
        arr.push(`${k}=${v}`)
      }
      return arr.join('&')
      // 对象排序-根据键名
      function ksort (o) {
        const sorted = {}
        const keys = Object.keys(o)
        keys.sort()
        keys.forEach((key) => {
          sorted[key] = o[key]
        })
        return sorted
      }
    }
    /*
    // 参数加密处理
    getSignature (str, publicKey) {
        const sha1 = require('js-sha1')
        // 进行sha1处理后的字符串
        str = sha1(str)
        const jse = new JSEncrypt()
        // 设置公钥
        jse.setPrivateKey(publicKey)
        // 设置需要加密的字符串
        const encrypted = jse.encrypt(str)
        return encrypted
    },
    // 解密
    decryptionkey (data) {
        const CryptoJS = require('crypto-js')
        var key = CryptoJS.enc.Utf8.parse('YWtuanhzeWZ0eDl6')
        var iv = CryptoJS.enc.Utf8.parse('aXZuanhzeWZ4eTlx')
        var decrypted = CryptoJS.AES.decrypt(data, key,
            {
                iv: iv,
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.Pkcs7
            })
        const res = CryptoJS.enc.Utf8.stringify(decrypted).toString()
        return res
    } */
}
